<script>

import axios from "axios";
export default {
  name: "request-btn",
  props:['id'],
    methods: {
      sendRequest() {
          console.log(this.id);
          axios.post(this.lroute('area.request'), {id:this.id}).then(res=>{
              this.$mitt.emit('request-success' )
          });
          return false;
      }
    }
}
</script>

<template>
  <button class="request__btn-comp" @click.prevent="sendRequest">
    Подати заявку
  </button>

</template>

