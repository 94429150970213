<script>
import PolygonModal from "@/components/mapPage/polygon-modal.vue";
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LPolygon, LPopup, LGeoJson, LMarker} from "@vue-leaflet/vue-leaflet";
import axios from "axios";
import UIkit from "uikit";
import Icon from "@/components/icon.vue";
import OfferPriceModal from "@/components/offer-price-modal.vue";


export default {
    name: "map-component",
    components: {

        OfferPriceModal,
        Icon,
        LMap,
        LTileLayer,
        LPolygon,
        LPopup,
        LGeoJson,
        LMarker,
        PolygonModal

    },
    data() {
        // url:'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}.png',
        return {
            map: null,
            minZoom: 6,
            ukr_json: '',
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            polygon: {
                latlngs: [[48.9994319007519, 35.8436691685002], [48.9930546760344, 35.8401342978572], [48.9932668703937, 35.8392808983373], [48.9996204938589, 35.842802294251], [48.9994319007519, 35.8436691685002]]
            },
            polygons: [],
             url: 'https://tiles.openstreetmap.org.ua/tile/{z}/{x}/{y}.png',
           // url: 'https://cdn.kadastr.live/proxy/topo100/{z}/{x}/{y}.png',
            // url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
            zoom: 6,
            center: [47.379433, 31.165579],
            bounds: [
                [44.387491, 22.137343],
                [52.369878, 40.228928]
            ],
            maxBounds: [
                [44.387491, 22.137343],
                [52.369878, 40.228928]
            ],
            // Стили гео
            geoJsonStyle: {
                color: 'grey',
                weight: 0,
                fillColor: 'grey',
                fillOpacity: 0.5
            },
            polygonsToRender: [],
            polygonColor: '#000',
            renderZoom: 13
        };
    },
    created() {
        this.getUkrGeoJson();
        this.getItems();

    },
    mounted() {

    },
    watch: {
        zoom(new_val, old__val) {
            // this.prepareForRender()
        }
    },
    methods: {
        moveHandler() {
            this.prepareForRender();
        },
        setMarkerCenter(){
            this.polygons.forEach(el=>{
                el.marker = this.getCenterOfPolygon(el)
            })


        },
        prepareForRender() {
            if (this.map.getZoom() >= this.renderZoom) {
                let bounds = this.map.getBounds()
                this.polygonsToRender = this.polygons.filter((pol) => {
                    if (this.getCenterOfPolygon(pol)[0] <= bounds['_northEast']['lat'] && this.getCenterOfPolygon(pol)[0] >= bounds['_southWest']['lat'] && this.getCenterOfPolygon(pol)[1] <= bounds['_northEast']['lng'] && this.getCenterOfPolygon(pol)[1] >= bounds['_southWest']['lng']) {
                        return pol
                    }
                })

            } else {
                this.polygonsToRender = [];
            }
        },
        getCenterOfPolygon(polygon) {
            var PI = 22 / 7
            var X = 0;
            var Y = 0;
            var Z = 0;
            let lat1, lon1, Lon, Hyp, Lat;
            polygon.latlngs.forEach(function (vertex, inex) {
                lat1 = vertex[0];
                lon1 = vertex[1];
                lat1 = lat1 * PI / 180
                lon1 = lon1 * PI / 180
                X += Math.cos(lat1) * Math.cos(lon1)
                Y += Math.cos(lat1) * Math.sin(lon1)
                Z += Math.sin(lat1)
            })
            Lon = Math.atan2(Y, X)
            Hyp = Math.sqrt(X * X + Y * Y)
            Lat = Math.atan2(Z, Hyp)
            Lat = Lat * 180 / PI
            Lon = Lon * 180 / PI
            return [Lat, Lon]
        },
        async getUkrGeoJson() {
            try {
                // let res = await axios.get('https://cdn.rawgit.com/johan/world.geo.json/34c96bba/countries/UKR.geo.json')
                let res = await axios.get('/parcels.json')

                this.ukr_json = await res.data;

            } catch (err) {
                console.log('Error load ukr json map', err)
            }
        },
        geoStyle() {
            return {
                color: 'grey',
                weight: 0,
                fillColor: 'grey',
                fillOpacity: 0.5
            }
        },
        async getItems() {
            try {
                let res = await axios.get('/polygons')
                this.polygons = res.data
                this.setMarkerCenter();
            } catch (err) {
                console.log('Error load ukr json map', err.message)
            }
        },
        showPolygonModal(item) {
            UIkit.modal(this.$refs.polygon_modal.$el).show()
        },

        readyHandler(map) {
            this.map = map;
        },
        plusZoom() {
            this.zoom++
        },
        minusZoom() {
            this.zoom--
        },
        changeSkin() {
            // console.log(this.map)
            this.url = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
            this.polygonColor = 'yellow'
        },

    }

}
</script>

<template>
    <div>
        {{ this.zoom }}
        <span style="color: red"> {{polygonsToRender.length}}</span>
    </div>
    <div id="map_container" class="map__container">

        <l-map ref="map" v-model:zoom="zoom" class="leaflet__map"
               v-model:center="center"
               v-model:attribution="attribution"
               :useGlobalLeaflet="false"
               :max-bounds="maxBounds"
               :minZoom="minZoom"
               :bounds="bounds"
               :subdomains="subdomains"
               @ready="readyHandler"
               @moveend='moveHandler'
        >
            <l-tile-layer
                :url="url"
                attribution=''
            ></l-tile-layer>

            <l-polygon v-if="zoom >= renderZoom" v-for="pol in polygonsToRender" @click="showPolygonModal(pol)"
                       :lat-lngs="pol.latlngs"
                       :color="polygonColor">
            </l-polygon>
            <l-geo-json :geojson="ukr_json"></l-geo-json>
<!--            <l-marker  v-if="zoom < renderZoom" v-for="marker in polygons" :lat-lng="marker.marker" ></l-marker>-->
        </l-map>
        <div class="map__control">
            <a href="" class="info__btn btn" @click.prevent="changeSkin">
                <icon name="info"/>
            </a>
            <a href="" @click.prevent="plusZoom" class="plus__btn btn control__btn">
                <icon name="plus"/>
            </a>
            <a href="" @click.prevent="minusZoom" class="minus__btn btn control__btn">
                <icon name="minus"/>
            </a>
        </div>
    </div>
    <PolygonModal ref="polygon_modal"/>
    <OfferPriceModal/>

</template>

<style lang="scss">
#map_container {

    position: relative;
    width: 100%;
    height: calc(100vh - 109px);
    z-index: 0;

    //.leaflet-control-container {
    //    display: none;
    //}

    .leaflet__map {
        position: relative;
        z-index: 1;
    }

    .map__control {
        position: absolute;
        bottom: 70px;
        right: 100px;
        z-index: 5;
        display: flex;
        flex-direction: column;

        .btn {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .info__btn {
            background-color: $black;
            color: white !important;
            margin-bottom: 20px;
        }

        .control__btn {
            background-color: white;
            color: #333333 !important;
            margin-bottom: 5px;
        }
    }

}

@media screen and (max-width: $breakpoint-df) {
    #map_container {
        height: calc(100vh - 70px);

        .map__control {
            left: 20px;
            right: initial;
            bottom: 30px;


        }
    }
}

</style>
