<script>
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'
import ErrorField from "@/components/error-field.vue";
import axios from "axios";
import Icon from "@/components/icon.vue";
import ServerErrors from "@/components/server-errors.vue";
export default {
    name: "login-form",
    components: {
        ServerErrors,
        Icon,
        ErrorField,

    },
    setup: () => ({v$: useVuelidate()}),
    data() {
        return {
            email: '',
            password: '',
            remember: false,
            server_errors: ''
        }
    },
    validations() {
        return {
            email: {required, email},
            password: {required, /*minLength: minLength(6)*/},
        }
    },
    methods: {
        showHidePass() {
            this.$refs.password.type = this.$refs.password.type == 'password' ? 'text' : 'password'
        },
        async submit() {
            this.server_errors = '';
            const result = await this.v$.$validate()
            if (!result) {
                // notify user form is invalid
                return;
            }
            try {
                const res = await axios.post(this.lroute('login'), this.$data);
                window.location.reload();
            } catch (err) {
                this.server_errors = err.response.data;
            }
            // perform async actions
        }
    }
}
</script>
<template>

    <div class="login__form-wrapper">
        <div class="form__title">
            Не маєте аккаунта? <a href="#register_modal" uk-toggle>Зареєструватись</a>
        </div>
        <form action="" class="login__form default__form" @submit.prevent="submit">
            <div class="input__row">
                <div class="input__wrapper" :class="{'invalid' : v$.email.$errors.length}">
                    <input type="email" v-model.trim="email" class="input" placeholder="E-mail">
                    <error-field :errors="v$.email.$errors"></error-field>
                </div>
                <div class="input__wrapper password" :class="{'invalid' : v$.password.$errors.length }">
                    <span class="show__pass-btn" @click="showHidePass">
                         <icon name="show-pass"/>
                    </span>
                    <input type="password" v-model.trim="password" ref="password" class="input"
                           placeholder="Пароль">
                    <error-field :errors="v$.password.$errors"></error-field>
                </div>
            </div>
            <div class="input__row ">
                <div class="save__remember">
                    <div class="checkbox__wrapper">
                        <label>
                            <input type="checkbox" class="checkbox uk-checkbox" v-model="remember">
                            Запам`ятати
                        </label>
                    </div>
                    <div class="remember">
                        <a href="#reset_password_modal" uk-toggle role="button">Забули пароль?</a>
                    </div>
                </div>
            </div>
            <template v-if="server_errors"></template>
            <div class="server__error">
                {{ server_errors.msg }}
            </div>
            <div class="btn__wrapper">
                <button type="submit" class="submit__btn">Увійти</button>
            </div>
            <div class="btn__delimiter">
                або
            </div>
            <div class="google__login">
                <a :href="this.lroute('login.redirect-to-google')" class="google__login-btn">
                    <span class="icon">
                     <icon name="google"/>
                    </span>
                    Увійти через Google
                </a>
            </div>
        </form>
    </div>


</template>

<style lang="scss">
.login__form-wrapper {
    max-width: 450px;
    width: 100%;

    .server__error {
        font-size: 12px;
        color: red;
        padding: 0 5px;
    }

    @include default-form;

    .login__form {
        .save__remember {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .remember {
                color: $black;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14px */
            }


        }

        .btn__delimiter {
            padding: 30px 0;
            position: relative;
            color: rgba(51, 51, 51, 0.50);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-align: center;

            &:before, &:after {
                position: absolute;
                top: 50%;
                content: '';
                left: 0;
                height: 1px;
                width: 42%;
                background: rgba(51, 51, 51, 0.20);
            }

            &:after {

                left: auto;
                right: 0;
            }
        }

        .google__login {
            .google__login-btn {
                @include transparent-btn;
                width: 100%;
                position: relative;
                .icon {
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                    background-color: $green;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color:  $white;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover {
                    .icon {
                        background-color: $white;
                        color: $green;
                    }
                }
            }
        }

    }

    @media screen and (max-width: $breakpoint-md) {
        .login__form {
            .google__login {
                .google__login-btn {
                    padding-left: 75px;
                }
            }
        }
    }
}

</style>
