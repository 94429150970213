<script>
import ErrorField from "@/components/error-field.vue";
import {vMaska} from "maska"
import {tel_validator} from "@/js/phone-validator.js";
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, sameAs} from '@vuelidate/validators'
import axios from "axios";
import PhoneInput from "@/components/partial/phone-input.vue";
import UIkit from "uikit";

const contactImage = new URL('@/assets/images/contact.png', import.meta.url).href
export default {
    name: "contact-form",
    components: {PhoneInput, ErrorField},
    directives: {'maska': vMaska},
    setup: () => ({v$: useVuelidate()}),
    props: {
        hide_message: {
            default: false
        },
        hide_radio: {
            default: false
        },
        modal_subject: {
            default: 'buy'
        }

    },
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            subject: 'buy', //sell
            message: '',
            contactImage: contactImage
        }
    },
    created() {
        this.subject = this.modal_subject;
    },
    validations() {
        return {
            name: {required},
            email: {required, email},
            phone: {
                required
            },
        }
    },
    methods: {
        async submit() {
            const result = await this.v$.$validate()
            if (!result) {
                // notify user form is invalid
                return;
            }
            try {
                const res = await axios.post(this.lroute('contacts'), this.$data);
                alert('ok');
                this.name = ''
                this.email = ''
                this.phone = ''
                this.subject = 'buy' //sell
                this.message = ''
                await this.$nextTick(() => {
                    this.v$.$reset()
                })


            } catch (error) {//

            }
            //alert('Valid ok')
            // perform async actions
        }
    }
}
</script>

<template>
    <div class="contact__form-wrapper">
        <div class="form__title">
            Заповніть форму і ми зв`яжемось з вами!
        </div>
        <form action="" class="contact__form default__form" @submit.prevent="submit">
            <div class="input__row">
                <div class="input__wrapper" :class="{'invalid' : v$.name.$errors.length}">
                    <input type="text" class="input" v-model.trim="name" placeholder="Ваше ім`я та прізвище">
                    <error-field :errors="v$.name.$errors"></error-field>
                </div>
            </div>
            <div class="input__row">
                <div class="input__wrapper" :class="{'invalid' : v$.email.$errors.length}">
                    <input type="text" class="input" v-model.trim="email" placeholder="Email">
                    <error-field :errors="v$.email.$errors"></error-field>
                </div>
            </div>
            <div class="input__row">
                <div class="input__wrapper" :class="{'invalid' : v$.phone.$errors.length}">
                    <PhoneInput v-model.trim="phone"/>
                    <error-field :errors="v$.phone.$errors"></error-field>
                </div>
            </div>
            <div class="input__row px__20" v-if="!hide_radio">
                <div class="row__subheader">
                    Виберіть тему повідомлення:
                </div>
                <div class="radio__btns">
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="subject" value="buy">
                            Продаж землі
                        </label>
                    </div>
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="subject" value="sell">
                            Купівля землі
                        </label>
                    </div>
                </div>
            </div>
            <div class="input__row" v-if="!hide_message">
                <div class="input__wrapper">
                    <img :src="contactImage" width="172" height="168" alt="" class="abs__img">
                    <textarea class="textarea" placeholder="Напишіть своє повідомлення..."
                              v-model.trim="message"></textarea>
                </div>
            </div>
            <div class="btn__wrapper">
                <button type="submit" class="submit__btn">
                    Відправити
                </button>
            </div>

        </form>
    </div>

</template>

<style lang="scss">
.contact__form-wrapper {
    max-width: 500px;
    width: 100%;


    @include default-form;

    .contact__form {
        gap: 10px;

        .px__20 {
            padding: 20px 0;
        }

        .row__subheader {
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
        }

        .radio__btns {
            display: flex;
            align-items: center;
            gap: 40px;
        }

        .abs__img {
            position: absolute;
            right: -100px;
            top: -60px;

        }

        .btn__wrapper {
            display: flex;
            justify-content: center;

            .submit__btn {
                max-width: 250px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .contact__form-wrapper .contact__form .abs__img {
        display: none;
    }
}
</style>
