<script>
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength, sameAs} from '@vuelidate/validators'
import {tel_validator} from "@/js/phone-validator.js";
import ErrorField from "@/components/error-field.vue";
import axios from "axios";
import Icon from "@/components/icon.vue";
import ServerErrors from "@/components/server-errors.vue";
import UIkit from "uikit";
import { VueTelInput } from 'vue-tel-input';
import PhoneInput from "@/components/partial/phone-input.vue";

export default {
    name: "register-form",
    components: {
        PhoneInput,
        ServerErrors,
        Icon,
        ErrorField,
        VueTelInput
    },
    setup: () => ({v$: useVuelidate()}),
    data() {
        return {
            name: '',
            surname: '',
            email: '',
            phone: null,
            password: '',
            repeat_pass: '',
            type: 'investor', //dealer
            server_errors: {}
        }
    },
    validations() {
        return {
            name: {required},
            surname: {required},
            email: {required, email},
            phone: {
                required
            },
            password: {required, minLength: minLength(6)},
            repeat_pass: {//
                sameAsPassword: sameAs(this.password),
                required
            },
        }
    },
    methods: {
        showHidePass(ref_name) {
            this.$refs[`${ref_name}`].type = this.$refs[`${ref_name}`].type == 'password' ? 'text' : 'password'
        },
        checkCountry(c){
            this.phone = '+'+c.dialCode
        },
        checkNumber(n){
            console.log(n)
        },
        async submit() {
            const result = await this.v$.$validate()
           this.server_errors = {}
            if (!result) {
                // notify user form is invalid
                return;
            }
            // perform async actions
            try {
                const res = await axios.post(this.lroute('cabinet.register'), this.$data);
                await UIkit.modal(document.getElementById('success_reg')).show();
                this.name = '';
                this.surname = '';
                this.email = '';
                this.phone = '';
                this.password = '';
                this.repeat_pass = '';


            } catch (error) {
                if (error.response.status == 422) {
                    this.server_errors = error.response.data.errors;
                }
                console.log('err', error)
                // Handle errors
            }

        }
    }
}
</script>

<template>
    <div class="register__form-wrapper">
        <div class="form__title">
            Вже маєте аккаунт? <a href="#login_modal" uk-toggle>Увійти</a>
        </div>
        <form action="" class="register__form default__form" @submit.prevent="submit">
            <div class="input__row col__2">
                <div class="input__wrapper" :class="{'invalid' : v$.name.$errors.length}">
                    <span class="label">
                            Ім`я *
                    </span>
                    <input type="text" class="input" v-model.trim="name" placeholder="Ваше Ім`я ">
                    <error-field :errors="v$.name.$errors"></error-field>
                </div>
                <div class="input__wrapper" :class="{'invalid' : v$.surname.$errors.length}">
                    <span class="label">
                            Прізвище *
                    </span>
                    <input type="text" class="input" v-model.trim="surname" placeholder="Ваше Прізвище">
                    <error-field :errors="v$.surname.$errors"></error-field>
                </div>
            </div>
            <!--            TODO: ЭТО ПОКА УБРАЛ ПОТОМУ ЧТО НЕОПРЕДЕЛЕННО С НИМИ -->
            <!--            <div class="input__row col__2">-->
            <!--                <div class="input__wrapper">-->
            <!--                    <span class="label">-->
            <!--                           Дата народження-->
            <!--                    </span>-->
            <!--                    <input type="text" class="input" placeholder="00 / 00 / 0000">-->
            <!--                </div>-->
            <!--                <div class="input__wrapper">-->
            <!--                    <span class="label">-->
            <!--                          ІПН *-->
            <!--                    </span>-->
            <!--                    <input type="text" class="input" placeholder="0000000000">-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="input__row col__2">
                <div class="input__wrapper" :class="{'invalid' : v$.email.$errors.length}">
                    <span class="label">
                         Email*
                    </span>
                    <input type="email" autocomplete="email" v-model.trim="email" class="input" placeholder="info@gmail.com">
                    <error-field :errors="v$.email.$errors"></error-field>
                </div>
                <div class="input__wrapper" :class="{'invalid' : v$.phone.$errors.length}">
                    <span class="label">
                         Телефон*
                    </span>
                    <PhoneInput v-model.trim="phone"/>
                    <error-field  :errors="v$.phone.$errors"></error-field>
                </div>
            </div>
            <div class="input__row col__2">
                <div class="input__wrapper password" :class="{'invalid' : v$.password.$errors.length }">
                        <span class="show__pass-btn" @click="showHidePass('pass')">
                    <icon name="show-pass"/>
                    </span>
                    <span class="label">
                         Пароль*
                    </span>
                    <input type="password" v-model.trim="password" ref="pass" class="input"
                           placeholder="*********">
                    <error-field :errors="v$.password.$errors"></error-field>
                </div>
                <div class="input__wrapper password" :class="{'invalid' : v$.repeat_pass.$errors.length }">
                    <span class="label">
                         Підтвердження паролю*
                    </span>
                    <span class="show__pass-btn" @click="showHidePass('repeat_pass')">
                  <icon name="show-pass"/>
                    </span>
                    <input type="password" ref="repeat_pass" v-model.trim="repeat_pass" class="input"
                           placeholder="*********">
                    <error-field :errors="v$.repeat_pass.$errors"></error-field>
                </div>
            </div>
            <div class="input__row">
                <div class="radio__btns">
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="type" value="investor">
                            Я інвестор
                        </label>
                    </div>
                    <div class="radio__wrapper">
                        <label>
                            <input type="radio" class="uk-radio radio" v-model="type" value="seller">
                            Я власник землі
                        </label>
                    </div>
                </div>
            </div>
                <ServerErrors v-if="server_errors" :errors="server_errors"/>
            <div class="input__row">
                <div class="policy">
                    Продовжуючи, Ви погоджуєшся з умовами <a href="">Публічної оферти</a> та <a href="">Політикою
                    Конфіденційності</a>
                </div>
            </div>
            <div class="btn__wrapper">
                <button type="submit" class="submit__btn">
                    Зареєструватись
                </button>
                <a :href="this.lroute('login.redirect-to-google')" class="google__login-btn">
                    <span class="icon">
                    <icon name="google"/>
                    </span>
                    Увійти через Google
                </a>
            </div>

        </form>
    </div>
</template>

<style lang="scss">
.register__form-wrapper {
    max-width: 645px;
    width: 100%;
    @include default-form;

    .radio__btns {
        display: flex;
        align-items: center;
        gap: 40px;
    }

    .policy {
        color: #242424;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 12px */
        a {
            font-weight: 600;
            text-decoration: underline !important;
        }
    }

    .default__form {
        .btn__wrapper {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            .google__login-btn {
                @include transparent-btn;
                width: 100%;
                position: relative;

                .icon {
                    position: absolute;
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                    background-color: $green;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: 0.3s;

                }

                &:hover {
                    .icon {
                        background-color: $white;
                        color: $green;
                    }
                }

            }
        }

        .input__wrapper.password {
            .show__pass-btn {
                top: 42px;
            }
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .register__form-wrapper .default__form .btn__wrapper {
        flex-direction: column;
    }
}
</style>
