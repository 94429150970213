import {Linear, gsap} from "gsap/index.js";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
export default function sectionAboutReviews(el) {
    if (!el) return;


    let mediaLaptop = window.innerWidth <= 1024
    let media600 = window.innerHeight < 600


    if (!media600) {
        let slider = null;

        function toggleScrollMagic() {
            if (!slider) {
                slider = initScrollSlider(el)
            }
            if (window.innerWidth < 1024 && slider) {
                slider.kill()
            }
        }

        toggleScrollMagic();

        window.addEventListener('resize', function () {
            if (!mediaLaptop) {
                toggleScrollMagic();
            }
        });

    }

    /* reviews slider START */
    const _reviews = document.querySelector('.advantages__swiper-slider');
    const _slider = _reviews.querySelector('.swiper');
    const _scrollbar = _reviews.querySelector('.slider-scrollbar');

    const _reviews_slider = new Swiper(_slider, {
        slidesPerView: 'auto',
        spaceBetween: 20,
        autoHeight: true,
        observer: true,
        scrollbar: {
            el: _scrollbar,
            draggable: true,
            hide: false,
        },
    });
    /* reviews slider END */
}

function initScrollSlider(el) {
    let timelineSectionAboutReviews = gsap.timeline({
        scrollTrigger: {
            trigger: el,
            start: "top center",
            end: "+=4000",
            scrub: 1.2,
            pin: '.advantages__section',
            anticipatePin: 0,
        },
    });
    let items = el.querySelectorAll(".anim-item");
    items.forEach((item, idx) => {
        let lastItem = items.length === idx;
        if (!lastItem) {
            timelineSectionAboutReviews
                .to(
                    `.anim-item-${idx + 1}`,
                    {
                        y: "0",
                        ease: Linear.easeNone,
                    },
                )
                .to(
                    `.anim-item-${idx}`,
                    {
                        scale: "0.9",
                        y: "-30px",
                        ease: Linear.easeNone,
                    },
                    "<"
                )
                .to(
                    `.anim-item-${idx - 1}`,
                    {

                        opacity: "0.1",
                        ease: Linear.easeNone
                    },
                    "<"
                )
                .to(
                    `.anim-item-${idx}`,
                    {
                        opacity: "0",
                        ease: Linear.easeNone
                    },
                    "<"
                )

        }
    });
    return timelineSectionAboutReviews;
}
