<script>
export default {
  name: "offer-price-btn",
    props:['id'],
  methods:{
    showModal(data){
      this.$mitt.emit('offer-price', data )
    }
  }
}
</script>
<template>
  <button  @click="showModal" class="request__btn-comp">
    Запропонувати ціну
  </button>

</template>

