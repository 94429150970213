import UIkit from 'uikit';
import {createApp} from 'vue/dist/vue.esm-bundler'
import loginForm from "@/components/login-form.vue";
import registerModal from "@/components/register-form.vue";
import contactForm from "@/components/contact-form.vue";
import newPasswordForm from "@/components/new-password-form.vue";
import AreaMap from "@/components/area-map.vue";
import ContactMap from "@/components/contact-map.vue";
import DirectorMessageModal from "@/components/director-message-modal.vue";
import RequestSuccess from "@/components/request-success.vue";
import FiltersResult from "@/components/partial/filters-result.vue";
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import sectionAboutReviews from "@/js/advantagesSlider.js";
import { createPinia } from 'pinia'
// Эта штука для обрабоки картинок
import.meta.glob([
    '../assets/images/**',
    '../assets/svg/**'
]);

import mitt from 'mitt';
import mapComponent from "@/components/mapPage/map-component.vue";
import mapFilter from "@/components/mapPage/map-filter.vue";
import resetPasswordForm from "@/components/reset-password-form.vue";
import listComponent from "@/components/mapList/list-component.vue";
import offerPriceModal from "@/components/offer-price-modal.vue";
import requestBtn from "@/components/partial/request-btn.vue";
import offerPriceBtn from "@/components/partial/offer-price-btn.vue";
import mapNative from "@/components/mapPage/map-native.vue";
import window from "inputmask/lib/global/window.js";


const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
const emitter = mitt();
const app = createApp({
    mounted() {
        this.stickyHeader();
        const advantagesSlider = document.getElementById('advantages_slider');
        sectionAboutReviews(advantagesSlider)
    },
    methods: {
        showModal(data){
            this.$mitt.emit('show-contact-modal',data)
        },

        stickyHeader() {
            const header = document.getElementById("header");
            window.addEventListener('scroll', () => {
                {
                    if (window.scrollY >= 250 ) {
                        header.classList.add("sticky");
                    }
                    if (window.scrollY <= 150) {
                        header.classList.remove("sticky");
                    }
                }
            })

        }
    },
    components: {
        'login-form': loginForm,
        'register-form': registerModal,
        'contact-form': contactForm,
        'map-component': mapComponent,
        'map-native': mapNative,
        'map-filter': mapFilter,
        'reset-password-form': resetPasswordForm,
        'new-password-form': newPasswordForm,
        'list-component': listComponent,
        'area-map': AreaMap,
        'contact-map': ContactMap,
        'offer-price-modal': offerPriceModal,
        'request-btn': requestBtn,
        'offer-price-btn': offerPriceBtn,
        'director-modal': DirectorMessageModal,
        'request-success':RequestSuccess,
        'filters-result': FiltersResult
    }
}).mixin({methods: {lroute:route}});
app.config.globalProperties.$mitt = emitter;
app.config.globalProperties.$global = Laravel;
app.use(pinia)
//app.use(router)

app.mount('#app')
