<script>
import Icon from "@/components/icon.vue";
import "leaflet/dist/leaflet.css";
import {LMap, LTileLayer, LMarker, LIcon} from "@vue-leaflet/vue-leaflet";

const imageUrl = new URL('@/assets/svg/contact-marker.svg', import.meta.url).href
export default {
    name: "contact-map",
    components: {
        Icon,
        LMap,
        LTileLayer,
        LMarker,
        LIcon
    },
    data() {
        return {
            map: null,
            minZoom: 6,
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
            zoom: 12,
            center: [47.379433, 31.165579],
            marker: [47.379433, 31.165579],
            iconUrl: imageUrl
        }
    },
    methods: {
        readyHandler(map) {
            this.map = map;
        },
        plusZoom() {
            this.zoom++
        },
        minusZoom() {
            this.zoom--
        },
    }
}
</script>

<template>
    <div class="contact__map">
        <l-map ref="map" v-model:zoom="zoom" class="leaflet__map"
               v-model:center="center"
               v-model:attribution="attribution"
               :useGlobalLeaflet="false"
               :minZoom="minZoom"
               @ready="readyHandler"
        >
            <l-tile-layer
                :url="url"
                :attribution='attribution'
            ></l-tile-layer>
            <l-marker :lat-lng="marker">
                <l-icon
                    :icon-url="iconUrl"
                    :iconSize="[30,30]"
                >
                </l-icon>
            </l-marker>
        </l-map>
        <div class="map__control">
            <a href="" @click.prevent="plusZoom" class="plus__btn btn control__btn">
                <icon name="plus"/>
            </a>
            <a href="" @click.prevent="minusZoom" class="minus__btn btn control__btn">
                <icon name="minus"/>
            </a>
        </div>
        <a href="" class="zoom__btn">
            <Icon name="zoom"/>
        </a>
        <div class="address__info">
            <div class="address__row">
                <div class="address__title">
                    Адреса:
                </div>
                <div class="address__description">
                    Зоряний провулок 19, Київ, 02000
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.leaflet__map {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: relative;


    .leaflet-control-container {
        display: none;
    }

    .leaflet-div-icon {
        background-color: transparent;
        border: none;
    }

}

.contact__map {
    margin-top: 60px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
    height: 400px;

    .address__info {
        position: absolute;
        left: 35px;
        top: 35px;
        z-index: 20;
        border-radius: 15px;
        background: #242424;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 270px;
        .address__row{
            .address__title{
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%; /* 12px */
                margin-bottom: 10px;
            }
            .address__description{
                color: rgba(255, 255, 255, 0.80);
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 12px */
            }
        }

    }

    .zoom__btn {
        width: 35px;
        height: 35px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(36, 36, 36, 0.20);
        position: absolute;
        top: 30px;
        z-index: 5;
        right: 30px;
        background-color: white;
    }

    .map__control {
        position: absolute;
        bottom: 30px;
        right: 30px;
        z-index: 5;
        display: flex;
        flex-direction: column;

        .btn {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgba(36, 36, 36, 0.2);
        }

        .info__btn {
            background-color: $black;
            color: white !important;
            margin-bottom: 20px;
        }

        .control__btn {
            background-color: white;
            color: #333333 !important;
            margin-bottom: 5px;
        }
    }
}

@media screen and (max-width: $breakpoint-md) {
    .contact__map {
        .zoom__btn {
            top: initial;
            left: 15px;
            right: initial;
            bottom: 110px;
            width: 40px;
            height: 40px;

        }
        .map__control {
            left: 15px;
            bottom: 15px;
        }
    }
}


</style>
