<script>
import UIkit from "uikit";


import Icon from "@/components/icon.vue";
import {useVuelidate} from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import ErrorField from "@/components/error-field.vue";
import axios from "axios";
const image = new URL('@/assets/images/modal-msg.png', import.meta.url).href
export default {
    name: "director-message-modal",
    components: {Icon, ErrorField},
    setup: () => ({v$: useVuelidate()}),
    data(){
        return{
            msg: '',
            image:image
        }
    },
    validations() {
        return {
            msg: {required},

        }
    },
    methods: {
        async submit() {
            const result = await this.v$.$validate()
            if (!result) {
                return;
            }
            try {
                const res = await axios.post(this.lroute('route'), this.$data);
                await this.$nextTick(() => this.v$.reset())
            } catch (err) {
                throw Error(err.message)
            }
            // perform async actions
        }
    }
}
</script>

<template>
    <div id="dir_message" class="uk-flex-top offer__price-modal message__modal" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-outside" type="button">
                <Icon name="close" class-name="icon__svg"/>
            </button>
            <div class="modal__content">
                <div class="modal__image-wrapepr">
                    <img :src="image" alt="" loading="lazy" width="120" height="90">
                </div>
                <div class="modal__title">
                    Лист директору компанії “Юніленд”
                </div>
                <div class="modal__subtitle">
                    Якщо ви чимось незадоволені в роботі нашої команди або щось працює не так як очікуєте - напишіть про це директору.
                </div>
                <form @submit.prevent="submit" class="offer__price-form">
                    <div class="input__wrapper" :class="{'invalid' : v$.msg.$errors.length}">
                        <textarea type="number" class="textarea" v-model.trim="msg" placeholder="Напишіть своє повідомлення..."></textarea>
                        <error-field :errors="v$.msg.$errors"></error-field>
                    </div>
                    <div class="form__btn">
                        <button class="submit__btn">
                            Надіслати
                        </button>
                    </div>
                </form>
            </div>


        </div>
    </div>

</template>

<style scoped lang="scss">
    @import '../scss/components/moda-styles';
</style>
