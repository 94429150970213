<script>
import {useVuelidate} from '@vuelidate/core'
import {required, email, minLength} from '@vuelidate/validators'
import ErrorField from "@/components/error-field.vue";
import axios from "axios";
import Icon from "@/components/icon.vue";

export default {
    name: "new-password-form",
    components: {
        Icon,
        ErrorField,

    },
    props:['frontToken', 'frontEmail'],
    setup: () => ({v$: useVuelidate()}),
    data() {
        return {
            email: '',
            token:'',
            password:'',
            password_confirmation:'',
        }
    },
    created() {
        this.email = this.frontEmail;
        this.token = this.frontToken;
    },
    validations() {
        return {
            email: {required, email},
            token:{required},
            password:{required},
            password_confirmation:{required}
        }
    },
    methods: {
        async submit() {
            const result = await this.v$.$validate()
            if (!result) {
                // notify user form is invalid
                return;
            }
            try {
                const res = await axios.post(this.lroute('password.update'), this.$data);
                window.location.href = '/';
                //alert('Посилання для відновлення пароля відправлено на E-Mail')
            } catch (err) {

            }
            // perform async actions
        }
    }
}
</script>
<template>

    <div class="login__form-wrapper">
                <form action="" class="login__form default__form" @submit.prevent="submit">
            <div class="input__row">
                <div class="input__wrapper" :class="{'invalid' : v$.email.$errors.length}">
                    <input type="email" v-model.trim="email" class="input" placeholder="E-mail" readonly >
                    <error-field :errors="v$.email.$errors"></error-field>
                </div>
            </div>
                    <div class="input__row">
                        <div class="input__wrapper" :class="{'invalid' : v$.password.$errors.length}">
                            <input type="password" v-model.trim="password" class="input" placeholder="Новий пароль">
                            <error-field :errors="v$.password.$errors"></error-field>
                        </div>
                    </div>
                    <div class="input__row">
                        <div class="input__wrapper" :class="{'invalid' : v$.password_confirmation.$errors.length}">
                            <input type="password" v-model.trim="password_confirmation" class="input" placeholder="Підтвердіть новий пароль">
                            <error-field :errors="v$.password_confirmation.$errors"></error-field>
                        </div>
                    </div>
            <div class="btn__wrapper">
                <button type="submit" class="submit__btn">Відновити пароль</button>
            </div>

        </form>
    </div>


</template>

<style lang="scss">
.login__form-wrapper {
    max-width: 450px;
    width: 100%;


    @include default-form;

    .login__form {
        .save__remember {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .remember {
                color: $black;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%; /* 14px */
            }


        }

        .btn__delimiter {
            padding: 30px 0;
            position: relative;
            color: rgba(51, 51, 51, 0.50);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-align: center;

            &:before, &:after {
                position: absolute;
                top: 50%;
                content: '';
                left: 0;
                height: 1px;
                width: 42%;
                background: rgba(51, 51, 51, 0.20);
            }

            &:after {

                left: auto;
                right: 0;
            }
        }



    }

    @media screen and (max-width: $breakpoint-md) {
        .login__form {
            .google__login {
                .google__login-btn {
                    padding-left: 75px;
                }
            }
        }
    }
}

</style>
